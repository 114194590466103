import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useState, useEffect } from 'react'
import Loader from 'react-loaders'
import personalPic from '../../assets/images/frc.JPG'
const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            I am currently a sophomore at The University of Hong Kong, studying
            Computer Science and Mathematics. I am currently trying to learn
            web-development on my own and this website is deeply inspired by
            Slobodan.
          </p>
          <p>
            I am quite into badminton, swimming, and portraiting. Also a huge
            fan of travelling. Moreover, a typical foodie, as my parents
            constantly pointing out.
          </p>
          <p>
            My dream since childhood is becoming a Full-Stack (Game) Developer.
            I like to fiddle with computers (softwares, operating systems, etc)
            since middle school. The more I learn about programming, the more I
            realize that it is a mix of pain and joy.
          </p>
        </div>
        <div className='image-zone'>
          <img src={personalPic} alt="frc game"></img>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About