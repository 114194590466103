import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LogoTitle from '../../assets/images/logo-s.png'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngular, faCss3, faGitAlt, faHtml5, faJsSquare, faReact } from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'

const Home = () => {
  // 以后要改内容这里array要改，下面index要改，animatedletters的index也要改
  const [letterClass, setLetterClass] = useState('text-animate')
  const isMobile = window.innerWidth < 768; // Adjust the threshold as needed
  const nameArray = isMobile ? ['o', 'n', 'a', 't', 'h', 'a', 'n'] : ['o', 'n', 'a', 't', 'h', 'a', 'n', ' ', 'Q', 'i', 'n'];
  const studentArray = isMobile ? ['Y', 'e', 'a', 'r', ' ', '2', ' ', '@', 'H', 'K', 'U'] : ['s', 'o', 'p', 'h', 'o', 'm', 'o', 'r', 'e', ' ', '@', ' ', 'H', 'K', 'U', '.']; 
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)

    return () => {
      clearTimeout(timeout)
    }
  }, []) 
  
  return (
    <>
    <div className="container home-page">
      <div className="text-zone">
        <h1>
          {!isMobile && (
            <>
              <span className={letterClass}>H</span>
              <span className={`${letterClass} _10`}>i</span>
              <span className={`${letterClass} _11`}>,</span>
              <br />
              <span className={`${letterClass} _12`}>I</span>
              <span className={`${letterClass} _13`}>'</span>
              <span className={`${letterClass} _14`}>m</span>
            </>
          )}
          <img src={LogoTitle} alt="developer" />
          <AnimatedLetters letterClass={letterClass}
          strArray={nameArray}
          idx={15}/>
          <br />
          <AnimatedLetters letterClass={letterClass}
          strArray={studentArray}
          idx={26}/>
        </h1>
        <h2>Computer Science / Mathematics / GAMING</h2>
        <Link to="/contact" className="flat-button">
          CONTACT ME
        </Link>
      </div>
      <div className='stage-cube-cont'>
        <div className='cubespinner'>
          <div className='face1'>
            <FontAwesomeIcon icon={faAngular} color="#DD0031" />
          </div>
          <div className='face2'>
            <FontAwesomeIcon icon={faHtml5} color="#F06529" />
          </div>
          <div className='face3'>
            <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
          </div>
          <div className='face4'>
            <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
          </div>
          <div className='face5'>
            <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
          </div>
          <div className='face6'>
            <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
          </div>
        </div>
      </div>
    </div>
    <Loader type="pacman" />
    </>
  )
}
export default Home
